import Layout from '../components/Layout';
import React from 'react';
import './me.scss';

type Props = Record<string, unknown>;

const BLOCK_CLASSNAME = 'me';

const Me: React.FC<Props> = () => (
  <Layout>
    <div className={`${BLOCK_CLASSNAME}__container`}>
      <h1>Page not found</h1>
    </div>
  </Layout>
);

export default Me;
